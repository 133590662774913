<template>
  <div class="content-detail">
    <a-page-header title="Created" @back="$router.push('/order/ltl/listltl')">
      <template #subtitle>
        <span>Created At : </span> 10:28:03 05-05-2022
      </template>
      <template #extra>
        <a-space>
          <!-- <a-button type="primary" size="large" class="normal-btn">Pay & Get labels</a-button> -->
          <a-button type="outline" size="large" class="outline-btn">Edit</a-button>
          <a-button type="outline" size="large" status="warning">cancel</a-button>
        </a-space>
      </template>
    </a-page-header>
    <div class="peopleinfo">
      <a-row :gutter="[20, 48]">
        <a-col :span="12">
          <div class="center-box">
            <div class="box-til">Sender</div>
            <div class="box-container">
              <a-row>
                <a-col :span="12">
                  <div class="title">Name :</div>
                  <span class="cont">Socrates</span>
                </a-col>
                <a-col :span="12">
                  <div class="title">Company :</div>
                  <span class="cont">DEF</span>
                </a-col>
              </a-row>
              <div class="title">Address :</div>
              <span class="cont">Yingdu Building, Zhichun Road, Beijing</span>
              <a-row>
                <a-col :span="12">
                  <div class="title">Phone :</div>
                  <span class="cont">123-1234-1234</span>
                </a-col>
                <a-col :span="12">
                  <div class="title">Email :</div>
                  <span class="cont">2872@163.com</span>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="center-box">
            <div class="box-til">Recipient</div>
            <div class="box-container">
              <a-row>
                <a-col :span="12">
                  <div class="title">Name :</div>
                  <span class="cont">Socrates</span>
                </a-col>
                <a-col :span="12">
                  <div class="title">Company :</div>
                  <span class="cont">DEF</span>
                </a-col>
              </a-row>
              <div class="title">Address :</div>
              <span class="cont">Yingdu Building, Zhichun Road, Beijing</span>
              <a-row>
                <a-col :span="12">
                  <div class="title">Phone :</div>
                  <span class="cont">123-1234-1234</span>
                </a-col>
                <a-col :span="12">
                  <div class="title">Email :</div>
                  <span class="cont">2872@163.com</span>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="[20, 48]">
        <a-col :span="24">
          <div class="center-box">
            <div class="box-til">Service</div>
            <div class="box-container">
              <a-row>
                <a-col :span="6">
                  <div class="title">Service Type :</div>
                  <span class="cont">ADS GROUND</span>
                </a-col>
                <a-col :span="6">
                  <div class="title">Signature Option :</div>
                  <span class="cont">/</span>
                </a-col>
                <a-col :span="6">
                  <div class="title">Public Cost : </div>
                  <span class="cont">$40.04</span>
                </a-col>
                <a-col :span="6">
                  <div class="title">Est Costt : </div>
                  <span class="cont">$27.22</span>
                </a-col>
              </a-row>
              <div>
                <div class="title">Charge : </div>
                <a-row>
                  <a-col :span="6">
                    <span class="title1">Base Charge :</span>
                    <span class="cont">$24.96</span>
                  </a-col>
                  <a-col :span="6"> <span class="title1">Surcharge :</span>
                    <span class="cont">$24.96</span>
                  </a-col>
                  <a-col :span="6"> <span class="title1">Fuel Charge :</span>
                    <span class="cont">$24.96</span>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="center-box">
      <div class="top-poerate-btn">
        <a-space>
          <span class="title2">Package Information</span>
          <a-button @click="handleClick">Change Address</a-button>
          <a-button type="outline" status="warning">Call Back</a-button>
          <a-button type="outline" status="warning">Cancel</a-button>
        </a-space>
        <div class="right-btn">
          <span class="title">Operabllity : </span>
          <a-radio-group type="button">
            <a-radio value="1">All</a-radio>
            <a-radio value="2">Change Address</a-radio>
            <a-radio value="3">Call Back</a-radio>
            <a-radio value="4">Cancel</a-radio>
          </a-radio-group>
        </div>
      </div>
      <a-table :columns="listColumns" :data="listData" :row-selection="rowSelection" :bordered="false"
        :pagination="false">
        <template #tracking="{ record }">
          <div @click="checkingId(record.key)">{{ record.tracking }}</div>
        </template>
        <template #action="{ record }">
          <a-button type="text" @click="changeaddress(record.key)">Change Address</a-button>
          <a-button type="text" status="warning">Return</a-button>
        </template>
      </a-table>
    </div>
    <!-- 修改地址的弹框 -->
    <div id="change-address-frame">
      <a-modal v-model:visible="visible" ok-text="Save" cancel-text="Reset" title-align="start" :render-to-body="false"
        @cancel="handleCancel" @before-ok="handleBeforeOk" :width="990">
        <template #title>
          <div class="change-frame">
            <div class="titlt-left">
              <span class="big-title">Change Address</span>
              <span class="small-title">Tracking id: 132284595234</span>
            </div>
            <div class="select-address">
              <a-form :model="addressform">
                <a-form-item field="name" label="Address">
                  <a-select placeholder="Please select ..." v-model="addressform.address">
                    <a-option value="section one">Section One</a-option>
                    <a-option value="section two">Section Two</a-option>
                    <a-option value="section three">Section Three</a-option>
                  </a-select>
                </a-form-item>
              </a-form>

            </div>
          </div>
        </template>
        <a-form :model="form" ref="formRef" size="medium" layout="vertical">
          <a-row :gutter="[20, 20]">
            <a-col :span="12">
              <a-form-item field="from_name" label="Name" :rules="[
                { required: true, message: 'name is required' },
                { maxLength: 35 }
              ]" :validate-trigger="['change', 'input']">
                <a-input v-model="form.from_name" placeholder="required 1-35 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item field="from_companyanme" label="Company Name" :rules="[{ maxLength: 35 }]"
                :validate-trigger="['change', 'input']">
                <a-input v-model="form.from_companyanme" placeholder="required 1-35 characters" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="[20, 20]">
            <a-col :span="12">
              <a-form-item field="from_street1" label="Address Line1" :rules="[
                { required: true, message: 'street1 is required' },
                { maxLength: 35 }
              ]" :validate-trigger="['change', 'input']">
                <a-input v-model="form.from_street1" placeholder="required 1-35 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item field="from_street2" label="Address Line2" :rules="[{ maxLength: 35 }]"
                :validate-trigger="['change', 'input']">
                <a-input v-model="form.from_street2" placeholder="required 1-35 characters" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="[20, 20]">
            <a-col :span="6">
              <a-form-item field="from_city" label="City" :rules="[
                { required: true, message: 'city is required' },
                { maxLength: 20 }
              ]" :validate-trigger="['change', 'input']">
                <a-input v-model="form.from_city" placeholder="required 1-20 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item field="from_state" label="State" :rules="[{ required: true, message: 'state is required' }]"
                :validate-trigger="['change', 'input']">
                <a-select v-model="form.from_state" placeholder="Please select">
                  <a-option value="section one">Section One</a-option>
                  <a-option value="section two">Section Two</a-option>
                  <a-option value="section three">Section Three</a-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item field="from_zip" label="Zip" :rules="[
                {
                  required: true,
                  type: 'number',
                  message: 'zip is required'
                },
                { minLength: 5, maxLength: 10 }
              ]" :validate-trigger="['change', 'input']">
                <a-input v-model="form.from_zip" placeholder="required 5-10 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item field="from_country" label="Country"
                :rules="[{ required: true, message: 'country is required' }]" :validate-trigger="['change', 'input']">
                <a-select v-model="form.from_country" placeholder="Please select">
                  <a-option value="section one">Section One</a-option>
                  <a-option value="section two">Section Two</a-option>
                  <a-option value="section three">Section Three</a-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="[20, 20]">
            <a-col :span="6">
              <a-row :gutter="[20, 20]">
                <a-col :span="16">
                  <a-form-item field="from_phone" label="Phone" :rules="[{ minLength: 10, maxLength: 15 }]"
                    :validate-trigger="['blur']">
                    <a-input v-model="form.from_phone" placeholder="required 10-15 characters" />
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item field="from_extension" label="Extension">
                    <a-input v-model="form.from_extension" placeholder="option" />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="6">
              <a-form-item field="from_email" label="Email" :rules="[
                {
                  match: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
                  message: 'email validation failed'
                }
              ]" :validate-trigger="['change', 'input']">
                <a-input v-model="form.from_email" placeholder="option" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-modal>
    </div>
    <!-- 点击tracking的弹框 -->
    <div id="click-teacking">
      <a-modal v-model:visible="trackingVisible" ok-text="Change Address" cancel-text="Return" title-align="start"
        :render-to-body="false" @cancel="trackingCancel" @ok="handleTrackingBeforeOk" :width="736">
        <template #title>
          <a-space>
            <!-- <img class="package-icon" src="../../../assets/images/home/Maskgroup.png"> -->
            <div class="tracking-detail">
              <div class="order-status">Shipped</div>
              <div class="order-icon">
                <span>Tracking :235345346456456</span>
                <img src="../../../assets/images/home/icons.png">
              </div>
            </div>
          </a-space>
        </template>
        <div class="rolad-line">地图</div>
        <div class="user-info">
          <div class="poNumber">PO Number : PO5386737373749</div>
          <a-timeline>
            <a-timeline-item dot-type="hollow" lineType="dashed" label="str1Edsion NJ" dotColor="#ececec">
              <span class="people1">Recipient</span>
              <br />
              <a-typography-text type="secondary" :style="{ fontSize: '22px', marginTop: '4px', color: '#121212' }">
                Sinclair Chan
              </a-typography-text>
            </a-timeline-item>
            <a-timeline-item label="main str111Avondale Estates GA" dot-type="hollow" lineType="dashed" dotColor="#000">
              <span class="people2">Sender</span>
              <br />
              <a-typography-text type="secondary" :style="{ fontSize: '22px', marginTop: '4px', color: '#121212' }">
                Json
              </a-typography-text>
            </a-timeline-item>
          </a-timeline>
        </div>
        <div class="road-steps">
          <a-timeline>
            <a-timeline-item dot-type="hollow" label=" Delivery failed because nowhere safe to leave package."
              dotColor="#13b966">
              Avondale Eatates,GA
            </a-timeline-item>
            <a-timeline-item dot-type="hollow" label=" Delivery failed because nowhere safe to leave package."
              dotColor="#ECECEC">
              Avondale Eatates,GA
            </a-timeline-item>
            <a-timeline-item dot-type="hollow" label=" Delivery failed because nowhere safe to leave package."
              dotColor="#ECECEC"> Avondale
              Eatates,GA
            </a-timeline-item>
          </a-timeline>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const title = route.query.title
const visible = ref(false)
const trackingVisible = ref(false)
const addressform = reactive({
  address: ''
})
const form = reactive({
  from_name: '',
  from_address: '',
  from_companyanme: '',
  from_phone: '',
  from_street1: '',
  from_street2: '',
  from_city: '',
  from_state: '',
  from_country: '',
  from_zip: '',
  from_email: '',
  from_extension: ''
})
const rowSelection = {
  type: 'checkbox',
  showCheckedAll: true
}
const listColumns = [
  {
    title: 'NO.',
    dataIndex: 'no'
  },
  {
    title: 'Tracking',
    dataIndex: 'tracking',
    slotName: 'tracking'
  },
  {
    title: 'Demension',
    dataIndex: 'demension'
  },
  {
    title: 'Weight(lb)',
    dataIndex: 'weight'
  },
  {
    title: 'SKU',
    dataIndex: 'sku'
  },
  {
    title: 'Status',
    dataIndex: 'status'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action'
  }
]
const listData = []
for (let i = 0; i < 5; i++) {
  listData.push({
    key: i,
    no: i,
    tracking: '134434243454',
    demension: '4,3,2',
    weight: '5',
    sku: 'ml',
    status: 'Arrived at Facllity',
    action: ''
  })
}
// 打开修改地址弹框
const handleClick = () => {
  visible.value = true
}
// 关闭修改地址弹框
const handleCancel = () => {
  visible.value = false
}
// 点击确认修改地址弹框关闭以前
const handleBeforeOk = (done) => {
  console.log(form)
  window.setTimeout(() => {
    done()
  }, 3000)
}
function checkingId (tracking) {
  console.log(tracking)
  trackingVisible.value = true
}
// 关闭TRACKING弹框
const trackingCancel = () => {
  visible.value = false
}
// 点击确认TRACKING弹框关闭以前
const handleTrackingBeforeOk = (done) => {
  console.log(form)
  window.setTimeout(() => {
    done()
  }, 3000)
}
onMounted(() => {
  // 去掉表格最后的一个的下划线
  const tableTr = document.querySelectorAll('.arco-table-tr')
  const tableTd = (tableTr[tableTr.length - 1].childNodes)
  tableTd.forEach((el) => {
    el.style = `border-bottom-color:transparent`
  })
})
</script>

<style scoped lang="less">
// 头部
:deep(.arco-page-header-header) {
  line-height: 43px;
}

:deep(.arco-page-header-back-btn) {
  font-size: 30px;
}

:deep(.arco-page-header-wrapper) {
  padding-left: 0;
}

:deep(.arco-page-header-title) {
  font-size: 30px;
  font-weight: 550;
}

:deep(.arco-page-header-divider) {
  background-color: #000;
  width: 2px;
  height: 25px;
}

:deep(.arco-page-header-subtitle) {
  font-weight: 400;
  font-size: 20px;
  color: #666;
}

:deep(.arco-page-header-subtitle>span) {
  color: #787879;
}

.content-detail {
  padding: 24px;
  font-family: 'Lato';
}

// 中间
.peopleinfo {
  padding-top: 24px;
}

.center-box {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 16px rgba(36, 107, 255, 0.07);
}

.box-til {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  padding: 20px;
  border-bottom: 1px solid #F2F5F8;
}

.box-container {
  padding: 20px;

  .title {
    font-size: 14px;
    color: #999;
    line-height: 40px;
  }

  .title1 {
    font-weight: 600;
    font-size: 12px;
    color: #666;
    line-height: 40px;
  }

  .cont {

    color: #333;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;

  }
}

:deep(.arco-descriptions-title) {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  padding: 20px;
  border-bottom: 1px solid #F2F5F8;
}

:deep(.arco-descriptions-body) {
  padding: 20px;
}

:deep(.arco-descriptions-item-value-inline) {
  color: #333;
  font-weight: 600;
  font-size: 16px;
  line-height: 44px;
}

// 底部
.top-poerate-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0 24px;
  height: 64px;

  .title2 {
    font-weight: 700;
    font-size: 20px;
  }

  .right-btn {
    line-height: 64px;
  }
}

:deep(.arco-table-tr) {
  height: 64px;

  .arco-table-th {
    background-color: #F8FAFF;
    color: rgba(109, 122, 155, 0.7);
    font-weight: 600;
  }

  .arco-table-td {
    color: #86909C;
    font-weight: 500;
    border-bottom: 1px solid #E5E6EB;
  }
}

:deep(.arco-table-container) {
  border-radius: 0 0 10px 10px;
}

.normal-btn {
  background: #000;

  &:hover {
    background: #000;
  }
}

.outline-btn {
  border-color: #000;
  color: #000;

  &:hover {
    border-color: #000;
    color: #000;
  }
}

// 右边的按钮组
.arco-radio-group-button {
  padding: 0;
}

.arco-radio-button {
  margin: 0;
  padding: 3px;
  color: #86909c;
  border-radius: 4px;

  &:hover {
    color: #000;
    background: #FFF7E3;
  }
}

.arco-radio-button.arco-radio-checked {
  color: #000;
  background: #FFF7E3;
}

.arco-btn-secondary,
.arco-btn-secondary[type='button'] {
  color: #86909C;
}

//  点击修改密码的弹框
#change-address-frame {
  :deep(.arco-modal-header) {
    height: 80px;

    .change-frame {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .titlt-left {
        .big-title {
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          margin-right: 20px;
        }

        .small-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: rgba(109, 122, 155, 0.7);
        }
      }

      .select-address {
        margin-right: 30px;

        .arco-form .arco-form-item {
          margin-bottom: 0;
        }
      }
    }
  }

  :deep(.arco-modal) {
    border-radius: 10px;
  }

  :deep(.arco-modal-footer) {
    border-top: none !important;
    text-align: center;

    button:nth-child(1) {
      border-color: #000;
      background: #fff;
      color: #000;
      height: 40px;
      width: 82px;
    }

    button:nth-child(2) {
      background: #000;
      height: 40px;
      width: 82px;
    }
  }

  :deep(.arco-modal-close-btn) {
    font-size: 28px;
  }
}

// 修改输入框样式
:deep(.arco-form-item-label-col>.arco-form-item-label) {
  color: #86909C;
}

:deep(.arco-input-wrapper),
:deep(.arco-select-view-single) {
  background: #FFFFFF;
  border: 1px solid #C4C2BC;
  border-radius: 2px;

  .arco-input::placeholder {
    color: #C9CDD4;
    font-weight: 500;
  }

  .arco-select-view-input::placeholder {
    color: #C9CDD4;
    font-weight: 500;
  }
}

:deep(.arco-input-wrapper .arco-input.arco-input-size-medium),
:deep(.arco-select-view-single.arco-select-view-size-medium .arco-select-view-input) {
  padding-top: 9px;
  padding-bottom: 9px;
}

:deep(.arco-checkbox-label) {
  color: #C9CDD4;
}

:deep(.arco-picker-size-mediumr) {
  height: 42px !important;
}

:deep(.arco-picker) {
  height: 42px;
  background: #fff;
  border: 1px solid #C4C2BC;
}

:deep(.arco-picker-size-medium input::placeholder) {
  color: #C9CDD4;
  font-weight: 500;
}

//  点击taracking的弹框
#click-teacking {
  :deep(.arco-modal-header) {
    height: 120px;
    box-shadow: 0px 2px 8px rgba(1, 50, 29, 0.12);

    .tracking-detail {
      color: #333333;

      .order-status {
        font-weight: 400;
        font-size: 28px;
      }

      .order-icon {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;

        span {
          padding-right: 5px;
        }
      }
    }
  }

  :deep(.arco-modal-body) {
    padding: 20px;
    position: relative;

    .rolad-line {
      background: #ccc;
      height: 315px;
      border-radius: 50px;
      text-align: center;
    }

    .user-info {
      width: 570px;
      box-shadow: 0 5px 7px rgba(0, 0, 0, 0.09);
      border-radius: 30px;
      position: absolute;
      background: #fff;
      top: 233px;
      left: 83px;
      padding: 21px 24px 42px 42px;
      box-sizing: border-box;

      .poNumber {
        margin-bottom: 25px;
        font-weight: 700;
        font-size: 20px;
        color: #13B966;

        span {
          font-weight: 500;
          font-size: 14px;
          color: #000000;
        }
      }

      .arco-timeline {
        .arco-timeline-item {
          .arco-timeline-item-content-wrapper {
            .arco-timeline-item-content {
              .people1 {
                color: #FFA54F;
              }

              .people2 {
                color: #13B966;
              }

            }

            .arco-timeline-item-label {
              font-weight: 400;
              font-size: 16px;
              color: #666;
            }
          }
        }
      }
    }

    .road-steps {
      margin-top: 220px;
      margin-left: 105px;

      .arco-timeline-item:first-child {
        .arco-timeline-item-dot {
          border-color: #13B966 !important;
        }
      }

      .arco-timeline-item-content {
        font-size: 24px;
        font-weight: 500;
        color: #333;
      }

      .arco-timeline-item-label {
        font-weight: 400;
        font-size: 16px;
        color: #666;
      }
    }
  }

  :deep(.arco-modal-header .arco-modal-close-btn) {
    font-size: 20px;
    font-weight: 600;
  }

  :deep(.arco-modal-footer) {
    border-top: none !important;
    text-align: center;
    padding-bottom: 50px;

    button:nth-child(1) {
      border: 1px solid #ff7d00;
      background: #fff;
      color: #ff7d00;
      height: 60px;
      width: 143px;
      border-radius: 4px;
    }

    button:nth-child(2) {
      background: #000;
      height: 60px;
      width: 143px;
      border-radius: 4px;
    }
  }
}
</style>
